import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { OVERVIEW_EVENT } from 'lib/tracking';
import {
  useGTM,
  useQuery,
  useAmplitude,
  useUserOrders,
  useBannerVisibility
} from 'hooks';
import { useFeatureFlag, FLAGS } from 'lib/featureFlags';
import { Loader } from '@teespring/ts-components';
import get from 'lodash/get';
import { delightedScript } from 'lib/delighted';
import useScript from 'hooks/useScripts';
// import useOnboardingChecklist from 'hooks/useOnboardingChecklist';
import axios from 'axios';
import WhatsNew from './WhatsNew';
import VerificationToast from './VerificationToast';
import EmailVerifyBar from './EmailVerifyBar';
import { API_HOST } from '../../constants';
// import OnboardingChecklist from './OnboardingChecklist';

import './Overview.scss';
import './Overview.css';

import IntegrationShelf from './IntegrationShelf';
import PromoBanner from './PromoBanner';
// import AnalyticsOverview from './AnalyticsOverview';
// import SpringBoardBanner from './SpringBoardBanner';

const Overview = () => {
  const emailVerificationEnabled = useFeatureFlag(FLAGS.EMAIL_VERIFICATION);
  // const [displayChecklist, setDisplayChecklist] = useState(true);

  // const { dismissed, dismissChecklist, checklist, loading } =
  //   useOnboardingChecklist();
  // useEffect(() => {
  //   setDisplayChecklist(!dismissed);
  // }, [dismissed]);
  const user = useSelector((state) => get(state, 'header.user'));
  const { userId } = useSelector((state) => state.user);
  // const handleDismissClick = () => {
  //   dismissChecklist();
  // };

  const isFetchingUserSubscriptions = useSelector(
    (state) => state.subscriptions.isFetchingUserSubscriptions
  );

  const { query } = useQuery();
  const { pushAccountToDataLayer } = useGTM();
  const { logEvent } = useAmplitude();

  const { verified, email } = user;

  // const recentlySignedUpUser = useSelector((state) =>
  //   get(state, 'user.recentlySignedUp', false)
  // );

  const needVerifyEmailBar =
    !query.get('verified') && emailVerificationEnabled && !verified;

  // setups
  useEffect(() => {
    logEvent(`${OVERVIEW_EVENT}.viewed`);
  }, [logEvent]);

  useEffect(() => {
    pushAccountToDataLayer();
  }, [pushAccountToDataLayer]);

  // delighted survey
  const { orders } = useUserOrders(user.id);
  const perPage = 10;

  const fetchListings = async () => {
    const { data } = await axios.get(`${API_HOST}/api/v1/user/listings`, {
      withCredentials: true,
      params: {
        userId,
        per: perPage,
        page: 1,
        sortBy: 'createdDate',
        orderBy: 'DESC'
      }
    });
    return data.listings;
  };
  let listings;
  const getListings = async () => {
    listings = await fetchListings();
  };
  useEffect(() => {
    getListings();
  }, []);
  useScript(
    delightedScript(user, listings?.length > 0, orders && orders?.length > 0)
  );

  // Banner active from active October 14th, 2024, 5:00 AM PST (12:00 PM UTC)
  const isBannerVisible = useBannerVisibility('2024-10-14T12:00:00Z', null);

  const upperBanners = [
    {
      id: 'promo_banner_adobe_express_banner',
      desktopSrc: require('../../assets/adobe_express_banner_dt.png').default,
      mobileSrc: require('../../assets/adobe_express_banner_mob.png').default,
      alt: 'Adobe express banner',
      link: 'https://amaze.co/_adobe-redemption/',
      isVisible: isBannerVisible
    },
    {
      id: 'promo_banner_your_ultimate_guide',
      desktopSrc: require('../../assets/hub_DT_OVERVIEW_1090x331.png').default,
      mobileSrc: require('../../assets/hub_MOB_OVERVIEW_344x294.5.png').default,
      alt: 'your ultimate guide',
      link: 'https://hub.spri.ng/guides/spring-24',
      isVisible: true
    },
    {
      id: 'promo_banner_samples_increase_sales',
      desktopSrc: require('../../assets/ThreeTimesSamples_Desktop.png').default,
      mobileSrc: require('../../assets/ThreeTimesSamples_Mobile.png').default,
      alt: 'samples increase sales',
      link: 'https://dashboard.teespring.com/samples',
      isVisible: true
    }
  ];

  const lowerBanners = [
    {
      id: 'promo_banner_college_ambassadors',
      desktopSrc: require('../../assets/dt_college_ambassadors.png').default,
      mobileSrc: require('../../assets/mob_college_ambassadors.png').default,
      alt: 'college ambassadors',
      link: 'https://share.hsforms.com/1HoP2rgLuR_STYT9qOt1qVQe19c5'
    }
  ];

  // Filter banners based on visibility
  const visibleUpperBanners = upperBanners.filter((banner) => banner.isVisible);

  return isFetchingUserSubscriptions ? (
    <Loader />
  ) : (
    <div className='overview_cont'>
      <div className='overview'>
        <VerificationToast className='overview__verification__toast' />
        <section className='overview__content'>
          {needVerifyEmailBar && <EmailVerifyBar email={email} />}
          <div className='firstBanner'>
            <PromoBanner banners={visibleUpperBanners} />
          </div>
          <div className='secondBanner'>
            {/* {!recentlySignedUpUser && <AnalyticsOverview />} */}

            {/* <SpringBoardBanner />*/}
          </div>
        </section>
      </div>
      <div className='thirdBanner'>
        <IntegrationShelf />
        <WhatsNew className='fourthBanner' />
      </div>
      <div className='fifthBanner'>
        <div className='overview'>
          <PromoBanner banners={lowerBanners} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
